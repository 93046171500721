import React, { ReactElement, useEffect, useRef, useState } from "react"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import {
  ActionContainer,
  CtaBlocBottom,
  CtaContainer,
  Feedback,
  FeedbackEditor,
  FeedbackInformation,
  FeedbackQuestionTitle,
  HideContainer,
  KeeperRateSpacing,
  MessageGray,
  MessageMotion,
  PeerRating,
  QuestionContainer,
  QuestionTitle,
  StepContainer,
  TextWithDefaultStyle,
} from "modules/rating/screens/Rating.styled"
import RateBanner from "modules/shared/components/Banner/Banner"
import { topToBottom } from "modules/shared/animations/variants"
import { Input } from "modules/shared/components/Input/Input"
import { ButtonWithIcon } from "modules/shared/components/Button/Button"
import { FiArrowRight } from "react-icons/fi"
import { CarriersName } from "types/carriers.types"
import { showBanner } from "utils/showBanner"
import { isUndefined } from "utils/isUndefined"
import { Order } from "models/order.model"
import StarRating from "modules/rating/components/StarRating/StarRating"

const MAX_CHARACTERS_NUMBER = 140

interface KeeperRateProps {
  order: Order
  onKeeperRateUpdated: (keeperRate: number, keeperFeedback: string) => void
  keeperRate: number | undefined
}

export const KeeperRate = ({ order, onKeeperRateUpdated, keeperRate }: KeeperRateProps): ReactElement => {
  const [keeperRatingState, setKeeperRatingState] = useState<number>()
  const [keeperFeedback, setKeeperFeedback] = useState<string>("")
  const [passedKeeperRate, setPassedKeeperRate] = useState<number>()

  useEffect(() => {
    setPassedKeeperRate(keeperRate)
  }, [])

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  /** Indirect reference to the keeperRatingState variable */
  const keeperRatingStateRef = useRef<number>(undefined)

  /** Indirect reference to the keeperFeedback variable */
  const keeperFeedbackRef = useRef<string>("")

  const isBanner =
    order.deliveryCarrierName === CarriersName.COLISSIMO && showBanner(order?.address?.keeper?.address?.zipcode)

  const isDisabled = isUndefined(keeperRatingState)

  keeperRatingStateRef.current = keeperRatingState
  keeperFeedbackRef.current = keeperFeedback

  const handleKeeperFeedbackChange = (text: string) => text.length <= MAX_CHARACTERS_NUMBER && setKeeperFeedback(text)

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current && !isUndefined(keeperRatingStateRef.current)) {
        onKeeperRateUpdated(keeperRatingStateRef.current, keeperFeedbackRef.current.trim())
      }
    }
  }, [])

  return (
    <PeerChallengeFeedbackContainer>
      <StepContainer>
        {isBanner && <RateBanner />}
        <ActionContainer wider={!isUndefined(passedKeeperRate)} marginTop={isBanner ? 18 : 90}>
          <>
            <PeerRating>
              <KeeperRateSpacing>
                <QuestionContainer>
                  <QuestionTitle isKeeperRate={!isUndefined(passedKeeperRate)}>
                    Comment évalueriez-vous le service fourni par{" "}
                    <b>Voisin-Relais {order?.address?.keeper?.firstName} ?</b>
                  </QuestionTitle>
                  <StarRating
                    rating={passedKeeperRate || keeperRatingState}
                    setRating={passedKeeperRate ? () => {} : setKeeperRatingState}
                    isKeeperRate={!isUndefined(passedKeeperRate)}
                  />
                </QuestionContainer>
              </KeeperRateSpacing>
              {isUndefined(passedKeeperRate) && (
                <HideContainer isVisible={!!keeperRatingState}>
                  <QuestionContainer>
                    <MessageMotion variants={topToBottom}>
                      <FeedbackInformation>
                        <FeedbackQuestionTitle>
                          Un message pour votre <b>Voisin-Relais {order?.address?.keeper?.firstName}</b> ?
                        </FeedbackQuestionTitle>
                        <MessageGray>
                          Ce message lui sera partagé sur son application{" "}
                          <TextWithDefaultStyle>🙂</TextWithDefaultStyle>
                        </MessageGray>
                      </FeedbackInformation>

                      <Feedback>
                        <FeedbackEditor>
                          <Input
                            maxChar={MAX_CHARACTERS_NUMBER}
                            placeholder={""}
                            behavior="textarea"
                            onChange={handleKeeperFeedbackChange}
                            value={keeperFeedback}
                          />
                        </FeedbackEditor>
                      </Feedback>
                    </MessageMotion>
                  </QuestionContainer>
                </HideContainer>
              )}
            </PeerRating>
          </>
          {isUndefined(passedKeeperRate) && (
            <HideContainer isVisible={!!keeperRatingState}>
              <CtaBlocBottom>
                <CtaContainer>
                  <ButtonWithIcon
                    text="Suivant"
                    disabled={isDisabled}
                    icon={<FiArrowRight />}
                    onClick={() => {
                      onKeeperRateUpdated(keeperRatingState, keeperFeedback.trim())
                      isDataSent.current = true
                    }}
                  />
                </CtaContainer>
              </CtaBlocBottom>
            </HideContainer>
          )}
        </ActionContainer>
      </StepContainer>
    </PeerChallengeFeedbackContainer>
  )
}
