import { OperationStates } from "models/operation-states.model"
import { Order } from "models/order.model"
import { Rating } from "models/rating.model"
import FullScreenContainer from "modules/shared/components/FullScreenContainer/FullScreenContainer"
import { Loader } from "modules/shared/components/Loader/Loader"
import React, { ReactElement, useEffect, useState } from "react"
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom"
import HelpModal from "modules/rating/components/HelpModal/HelpModal"
import Menu from "modules/rating/components/Menu/Menu"
import Error from "modules/rating/screens/Error/Error"
import FeedbackSubmission from "modules/rating/screens/FeedbackSubmission/FeedbackSubmission"
import OrderAlreadyRated from "modules/rating/screens/OrderAlreadyRated"
import "modules/rating/screens/styles.scss"
import * as orderQueries from "services/graphql/queries/orders.queries"
import * as ratingMutations from "services/graphql/mutations/rating.mutations"
import { useApolloClient, useMutation } from "@apollo/client"
import { v1 as uuidV1 } from "uuid"
import { Survey } from "modules/rating/screens/Survey/Survey"
import { NPS } from "modules/rating/screens/NPS/NPS"
import { KeeperRate } from "modules/rating/screens/KeeperRate/KeeperRate"
import { Question } from "modules/rating/screens/Question/Question"
import { Rate } from "modules/rating/screens/Rate/Rate"
import { Tip } from "modules/rating/screens/Tip/Tip"
import { Feedback } from "modules/rating/screens/Feedback/Feedback"
import { AnswerInput, RatingVersions } from "types/rating.types"
import { isUndefined } from "utils/isUndefined"
import { mergeAndRemoveDuplicates } from "utils/mergeAndRemoveDuplicated"
import { CarriersName } from "types/carriers.types"
import { initialTipState } from "modules/rating/datas/Tip/TipDatas"

export default function KeeperRatingRoutes(): ReactElement {
  const history = useHistory()
  const { path } = useRouteMatch()
  const client = useApolloClient()
  const { orderId } = useParams<{ orderId: string }>()

  const [rating, setRating] = useState<Rating | undefined>(new Rating(orderId))

  const [loading, setLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<Order>()
  const [error, setError] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [submissionState, setSubmissionState] = useState<OperationStates>(OperationStates.Success)
  const [alreadyRated, setAlreadyRated] = useState<boolean>(false)
  const [deliveryCarrierName, setDeliveryCarrierName] = useState<string>(null)

  const [submitRatingMutation] = useMutation<Rating, ratingMutations.SubmitRatingInput>(ratingMutations.submitRating)
  const [updateRatingMutation] = useMutation<Rating, ratingMutations.UpdateRatingInput>(ratingMutations.updateRating)

  if (!orderId && !error) {
    setError(true)
  }

  const updateRatingSession = (ratingInput: Rating): void => {
    // eslint-disable-next-line no-undef
    window.sessionStorage.setItem("rating", JSON.stringify(ratingInput))
  }

  const removeRatingSession = () => {
    // eslint-disable-next-line no-undef
    window.sessionStorage.removeItem("rating")
  }

  const getRatingSession = (): Rating | null => {
    // eslint-disable-next-line no-undef
    const session = window.sessionStorage.getItem("rating")
    return JSON.parse(session)
  }

  /** Select a path to redirect on the first render */
  const selectInitialPathToRedirect = (newRating: Rating, order: Order) => {
    if (order.deliveryCarrierName === CarriersName.COLISSIMO) {
      if (newRating.rate === undefined) {
        history.push(`/rating/${orderId}/rate`)
      } else if (isUndefined(newRating.answers)) {
        history.push(`/rating/${orderId}/question`)
      } else if (newRating?.answers?.find(question => question.order === 6) === undefined) {
        history.push(`/rating/${orderId}/survey`)
      } else if (newRating.feedback === undefined) {
        history.push(`/rating/${orderId}/feedback`)
      } else {
        history.push(`/rating/${orderId}/submit`)
      }
    } else {
      if (isUndefined(newRating?.nps)) {
        history.push(`/rating/${orderId}/nps`)
      } else if (newRating?.answers?.find(question => question.order === 1) === undefined) {
        history.push(`/rating/${orderId}/question`)
      } else if (isUndefined(newRating?.rate_keeper)) {
        history.push(`/rating/${orderId}/keeper-rate`)
      } else if (
        newRating?.answers?.find(question => question.order === 4) === undefined &&
        newRating?.rate_keeper === 5
      ) {
        history.push(`/rating/${orderId}/tip`)
      } else {
        history.push(`/rating/${orderId}/submit`)
      }
    }
  }

  const getOrder = async (): Promise<void> => {
    setLoading(true)

    try {
      const response = await client.query({
        query: orderQueries.getOrder,
        variables: {
          id: orderId,
        },
      })

      if (response?.data?.orderByIdForRating) {
        const order = response.data.orderByIdForRating

        if (order.status !== "DELIVERED_TO_RECIPIENT") {
          setError(true)
          setLoading(false)
          return
        }

        const ratingFromSession = getRatingSession()

        // It's already rated
        if (order.alreadyRated && !ratingFromSession) {
          setAlreadyRated(true)
          setLoading(false)
          setDeliveryCarrierName(order.deliveryCarrierName)
          return
        }

        const newRating = {
          ...rating,
          appSessionId: uuidV1(),
          ...ratingFromSession,
        }
        setRating(newRating)
        updateRatingSession(newRating)

        setOrder(order)
        selectInitialPathToRedirect(newRating, order)
      }
    } catch (e) {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    getOrder()
  }, [orderId])

  /**
   * API - Create the rating entry.
   *
   * @param ratingInput - Rating to create
   */
  const submitRating = async (ratingInput: Rating): Promise<void> => {
    setSubmissionState(OperationStates.InProgress)

    try {
      await submitRatingMutation({
        variables: {
          rating: {
            orderId: ratingInput.orderId,
            appSessionId: ratingInput.appSessionId,
            rate: ratingInput.rate,
            version:
              order.deliveryCarrierName === CarriersName.COLISSIMO
                ? RatingVersions.COLISSIMO_V2
                : RatingVersions.STANDARD_V4,
            feedback_keeper: ratingInput?.feedback_keeper || undefined,
          },
        },
      })

      setSubmissionState(OperationStates.Success)
    } catch (e) {
      setSubmissionState(OperationStates.Error)
    }
  }

  /**
   * API - Update the rating.
   * If this is the last action needed for the rating, we reset the appSessionId.
   *
   * @param finishedSubmit - Is the form finished for this client ?
   * @param ratingInput - Updated rating to send
   *
   */
  const updateRating = async (ratingInput: Rating, finishedSubmit?: boolean): Promise<void> => {
    setSubmissionState(OperationStates.InProgress)

    try {
      await updateRatingMutation({
        variables: {
          rating: {
            orderId: ratingInput.orderId,
            appSessionId: ratingInput.appSessionId,
            feedback: ratingInput.feedback,
            answers: ratingInput.answers,
            nps: ratingInput.nps,
            rate_keeper: ratingInput.rate_keeper,
            feedback_keeper: ratingInput.feedback_keeper,
            carrierName: order.deliveryCarrierName,
          },
        },
      })

      if (finishedSubmit) {
        removeRatingSession()
        history.push(`/rating/${order.id}/submit`)
      }

      setSubmissionState(OperationStates.Success)
    } catch (e) {
      setSubmissionState(OperationStates.Error)
    }
  }

  /**
   * Update rating "Rate" and submit for the first time to the API.
   *
   * @param rate - Rate provided by Client
   * @param keeperFeedback - Keeper feedback sent by Client
   *
   */
  const onRateUpdated = async (rate: number, keeperFeedback?: string): Promise<void> => {
    let newRating = {
      ...rating,
      rate,
      feedback_keeper: keeperFeedback,
    }

    await submitRating(newRating)

    /** Update the 'rate_keeper' based on the 'rate' field */
    newRating = {
      ...newRating,
      rate_keeper: Math.round(rate / 2),
    }

    await updateRating(newRating)

    setRating(newRating)
    updateRatingSession(newRating)

    history.push(`/rating/${order.id}/question`)
  }

  /**
   * Update "PlatformRate" answers and send for update to the API.
   *
   * @param nps - NPS rate provided by Client
   */
  const onNPSUpdated = async (nps: number): Promise<void> => {
    const newRating = {
      ...rating,
      nps: nps,
    }

    setRating(newRating)
    updateRatingSession(newRating)

    /**
     * It is necessary to submit the rating first in order to create a row in the database.
     * */
    await submitRating(newRating)
    await updateRating(newRating)

    history.push(`/rating/${order.id}/question`)
  }

  /**
   * Update "Question" answers and send for update to the API.
   *
   * @param answers - Answers for the "Question" form
   * @param feedback - Feedback sent by Client
   */
  const onQuestionUpdated = async (answers: AnswerInput[], feedback: string): Promise<void> => {
    const newRating = {
      ...rating,
      answers: mergeAndRemoveDuplicates(rating.answers || [], answers),
      feedback,
    }

    setRating(newRating)
    updateRatingSession(newRating)
    await updateRating(newRating)

    order.deliveryCarrierName === CarriersName.COLISSIMO
      ? history.push(`/rating/${order.id}/survey`)
      : history.push(`/rating/${order.id}/rate-keeper`)
  }

  /**
   * Update "Survey" answers and send for update to the API.
   *
   * @param answers - Answers for the "Survey" form
   */
  const onSurveyUpdated = async (answers: AnswerInput[]): Promise<void> => {
    const newRating = {
      ...rating,
      answers: mergeAndRemoveDuplicates(rating.answers || [], answers),
    }

    setRating(newRating)
    updateRatingSession(newRating)
    await updateRating(newRating)

    history.push(`/rating/${order.id}/feedback`)
  }

  /**
   * Update local state rating "Feedback"
   *
   * @param feedback - Feedback sent by Client
   */
  const onFeedbackUpdated = async (feedback: string): Promise<void> => {
    const newRating = {
      ...rating,
      feedback,
    }

    setRating(newRating)
    updateRatingSession(newRating)
    await updateRating(newRating, order.deliveryCarrierName === CarriersName.COLISSIMO)

    order.deliveryCarrierName !== CarriersName.COLISSIMO && history.push(`/rating/${order.id}/rate`)
  }

  /**
   * Update "Tip" answers and send for update to the API.
   *
   * @param answers - Answers for the "Tip" form
   * @param isSkip - Was the tip skipped ?
   */
  const onTipUpdated = async (answers: AnswerInput[], isSkip?: boolean): Promise<void> => {
    const newRating = {
      ...rating,
      answers: mergeAndRemoveDuplicates(rating.answers || [], answers),
    }

    setRating(newRating)
    updateRatingSession(newRating)

    // If tip was skipped, redirect to the last page
    await updateRating(newRating, isSkip)
  }

  /**
   * Update "Tip" answers and send for update to the API.
   *
   * @param keeperRate - Keeper rate provided by Client
   * @param keeperFeedback - Keeper feedback sent by Client
   */
  const onKeeperRateUpdated = async (keeperRate: number, keeperFeedback: string): Promise<void> => {
    let newRating = {
      ...rating,
      rate_keeper: keeperRate,
      feedback_keeper: keeperFeedback,
    }

    const showTipPage = newRating.rate_keeper === 5

    if (!showTipPage && order.deliveryCarrierName !== CarriersName.COLISSIMO) {
      newRating = {
        ...newRating,
        answers: mergeAndRemoveDuplicates(rating.answers || [], [{ ...initialTipState.q4, note: "" }]),
      }
    }

    setRating(newRating)
    updateRatingSession(newRating)
    await updateRating(newRating)

    showTipPage ? history.push(`/rating/${order.id}/tip`) : history.push(`/rating/${order.id}/submit`)
  }

  return (
    <FullScreenContainer>
      <Menu />
      <HelpModal visible={showModal} setVisible={setShowModal} />
      {!loading && alreadyRated && <OrderAlreadyRated deliveryCarrierName={deliveryCarrierName} />}
      {!loading && !alreadyRated && order && (
        <Switch>
          <Route exact path={`${path}/rate`}>
            <Rate order={order} onRateUpdated={onRateUpdated} />
          </Route>
          <Route exact path={`${path}/nps`}>
            <NPS order={order} onNPSUpdated={onNPSUpdated} />
          </Route>
          <Route exact path={`${path}/question`}>
            <Question rate={rating.rate} order={order} onQuestionUpdated={onQuestionUpdated} />
          </Route>
          <Route exact path={`${path}/survey`}>
            <Survey onSurveyUpdated={onSurveyUpdated} />
          </Route>
          <Route exact path={`${path}/feedback`}>
            <Feedback order={order} submit={onFeedbackUpdated} />
          </Route>
          <Route exact path={`${path}/rate-keeper`}>
            <KeeperRate keeperRate={rating.rate_keeper} order={order} onKeeperRateUpdated={onKeeperRateUpdated} />
          </Route>
          <Route exact path={`${path}/tip`}>
            <Tip order={order} onTipUpdated={onTipUpdated} />
          </Route>
          <Route exact path={`${path}/submit`}>
            <FeedbackSubmission submissionState={submissionState} order={order} />
          </Route>
          <Redirect to={`${path}/rate`} />
        </Switch>
      )}
      {loading && (
        <div className="peer-feedback-loader">
          <Loader />
        </div>
      )}
      {error && <Error />}
      {/*<Footer onHelpRequested={() => setShowModal(true)} />*/}
    </FullScreenContainer>
  )
}
